* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

img {
  max-width: 100%;
}
